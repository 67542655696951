import React from "react";
import image2 from "../images/image2.png";
import lv from "../images/lv.png";
import image3 from "../images/image3.png";
import image4 from "../images/image4.png";
import image5 from "../images/image5.png";

const AuctionSection = () => {
  return (
    <section className="w-full py-8 bg-white flex flex-col items-center space-y-4">
      {/* Centered Line with Text */}
      <div className="text-center text-l font-semibold text-gray-800 uppercase">
        Auctions from your favorite luxury brands and providers
      </div>

      {/* Logos Section */}
      <div className="flex justify-evenly items-center flex-wrap w-full px-4 space-y-4">
        {/* Logos - Adjust these images and paths as needed */}
        <img
          src={image2}
          alt="Brand Logo 1"
          className="h-16 w-auto flex-grow object-contain max-w-[150px]"
        />
        <img
          src={lv}
          alt="Brand Logo 2"
          className="h-16 w-auto flex-grow object-contain max-w-[150px]"
        />
        <img
          src={image3}
          alt="Brand Logo 3"
          className="h-16 w-auto flex-grow object-contain max-w-[150px]"
        />
        <img
          src={image4}
          alt="Brand Logo 4"
          className="h-16 w-auto flex-grow object-contain max-w-[150px]"
        />
        <img
          src={image5}
          alt="Brand Logo 5"
          className="h-16 w-auto flex-grow object-contain max-w-[150px]"
        />
      </div>
    </section>
  );
};

export default AuctionSection;
