import React from "react";

const Footer = () => {
  return (
    <footer className="w-full bg-black text-white py-8 text-xs">
      <div className="max-w-6xl mx-auto px-6 sm:px-10">
        {/* Top Section with Links */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 border-b border-white pb-8 mb-8">
          {/* Product Links */}
          <div>
            <h5 className="text-white font-semibold mb-4">Product</h5>
            <ul className="space-y-2 sm:space-y-4">
              <li>
                <a href="#" className="hover:text-white">
                  Overview
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white">
                  Pricing
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white">
                  Browse
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white">
                  Accessibility
                </a>
              </li>
            </ul>
          </div>

          {/* Support Links */}
          <div>
            <h5 className="text-white font-semibold mb-4">Support</h5>
            <ul className="space-y-2 sm:space-y-4">
              <li>
                <a href="#" className="hover:text-white">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white">
                  Developers
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white">
                  Documentation
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white">
                  Integrations
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white">
                  Reports
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white">
                  Webinar
                </a>
              </li>
            </ul>
          </div>

          {/* Company Links */}
          <div>
            <h5 className="text-white font-semibold mb-4">Company</h5>
            <ul className="space-y-2 sm:space-y-4">
              <li>
                <a href="#" className="hover:text-white">
                  About
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white">
                  Press
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white">
                  Events
                </a>
              </li>
              <li>
                <a href="#" className="hover:text-white">
                  Careers
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Section with Social Links & Disclaimer */}
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start text-center md:text-left space-y-4 md:space-y-0">
          {/* Left Side: Copyright */}
          <p className="md:w-1/2">&copy; 2024 APEVAULT All rights reserved</p>

          {/* Right Side: Links and Social Icons */}
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 md:w-1/2 justify-center md:justify-end">
            <a href="#" className="hover:text-white">
              Terms
            </a>
            <a href="#" className="hover:text-white">
              Privacy
            </a>
            <a href="#" className="hover:text-white">
              Contact
            </a>

            {/* Social Icons */}
            <div className="flex space-x-4">
              <a href="#" className="hover:text-white">
                {/* YouTube Icon */}
                <svg
                  className="w-5 h-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M23.5 6.2c-.3-1.2-1.3-2.2-2.5-2.4C18.9 3.4 12 3.4 12 3.4s-6.9 0-8.9.4C1.9 4.1.9 5.1.6 6.2 0 8.1 0 12 0 12s0 3.9.6 5.8c.3 1.2 1.3 2.2 2.5 2.4 2 .4 8.9.4 8.9.4s6.9 0 8.9-.4c1.2-.3 2.2-1.3 2.5-2.4.6-1.9.6-5.8.6-5.8s0-3.9-.6-5.8zM9.8 15.6v-7.2l6.5 3.6-6.5 3.6z" />
                </svg>
              </a>

              <a href="#" className="hover:text-white">
                {/* Facebook Icon */}
                <svg
                  className="w-5 h-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M22.675 0H1.325C.594 0 0 .593 0 1.324v21.351C0 23.407.593 24 1.325 24h11.496V14.706h-3.13v-3.63h3.13V8.411c0-3.1 1.893-4.787 4.656-4.787 1.324 0 2.463.099 2.795.142v3.24h-1.918c-1.504 0-1.794.715-1.794 1.763v2.312h3.587l-.467 3.63h-3.12V24h6.115C23.407 24 24 23.407 24 22.675V1.324C24 .593 23.407 0 22.675 0z" />
                </svg>
              </a>

              <a href="#" className="hover:text-white">
                {/* Twitter Icon */}
                <svg
                  className="w-5 h-5 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M22.46 6.004c-.77.35-1.6.587-2.46.693a4.301 4.301 0 001.884-2.37 8.594 8.594 0 01-2.726 1.04A4.29 4.29 0 0016.11 4c-2.358 0-4.272 1.91-4.272 4.272 0 .336.04.664.11.98-3.547-.175-6.7-1.87-8.812-4.447a4.237 4.237 0 00-.58 2.15c0 1.482.753 2.792 1.898 3.556a4.274 4.274 0 01-1.938-.534v.056c0 2.07 1.473 3.804 3.43 4.197-.358.1-.734.152-1.12.152-.275 0-.546-.027-.809-.077a4.296 4.296 0 004.008 2.98A8.6 8.6 0 012 19.543a12.136 12.136 0 006.586 1.934c7.898 0 12.217-6.544 12.217-12.217 0-.187 0-.374-.013-.56A8.683 8.683 0 0024 4.538a8.58 8.58 0 01-2.54.694z" />
                </svg>
              </a>

              <a
                href="#"
                className="hover:text-gray-300 transition-colors duration-200"
              >
                {/* Instagram Icon */}
                <svg
                  className="w-5 h-5 sm:w-6 sm:h-6 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M7.75 2h8.5A5.75 5.75 0 0122 7.75v8.5A5.75 5.75 0 0116.25 22h-8.5A5.75 5.75 0 012 16.25v-8.5A5.75 5.75 0 017.75 2zm0 1.5A4.25 4.25 0 003.5 7.75v8.5A4.25 4.25 0 007.75 20.5h8.5a4.25 4.25 0 004.25-4.25v-8.5a4.25 4.25 0 00-4.25-4.25h-8.5zm4.25 3.25a3 3 0 110 6 3 3 0 010-6zm0 1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm5.04-.29a.9.9 0 110 1.79.9.9 0 010-1.8z" />
                </svg>
              </a>

              <a
                href="#"
                className="hover:text-gray-300 transition-colors duration-200"
              >
                {/* LinkedIn Icon */}
                <svg
                  className="w-5 h-5 sm:w-6 sm:h-6 fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path d="M4.98 3.5a2.5 2.5 0 100 5 2.5 2.5 0 000-5zM3.5 6a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM3.48 8h2.94v12H3.48zM11.79 8h2.68v1.42h.04a2.94 2.94 0 012.64-1.49c2.83 0 3.35 1.9 3.35 4.36v6.7h-2.96v-6c0-1.43-.03-3.28-2-3.28-2 0-2.3 1.56-2.3 3.18v6.1H11.8z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
