import React from "react";
import "./index.css";

import NavBar from "./components/NavBar.com";
import AuctionInfoSection from "./components/AuctionInfoSection.com";
import MarketplaceSection from "./components/MarketplaceSection.com";
import FeaturesSection from "./components/FeaturesSection.com";
import Footer from "./components/Footer.com";
import AuctionSection from "./components/AuctionSec.com";

const App = () => {
  return (
    <div className="bg-white-100">
      <header className="w-full">
        <NavBar />
      </header>

      <main className="flex-grow w-full max-w-screen-xl mx-auto px-4">
        <AuctionSection />
        <AuctionInfoSection />

        <MarketplaceSection />
        <FeaturesSection />
      </main>

      <footer className="w-full">
        <Footer />
      </footer>
    </div>
  );
};

export default App;
