import React from "react";
import combinedPhoneImage from "../images/auction.png"; // Update with the correct path

const AuctionInfoSection = () => {
  return (
    <section className="flex flex-col md:flex-row items-center justify-center w-full p-4 sm:p-8 bg-white space-y-6 md:space-y-0 md:space-x-8 my-4">
      {/* Left Text Section */}
      <div className="flex flex-col justify-center items-center text-center md:items-start md:text-left space-y-4 w-full md:w-1/2">
        <h3 className="text-xs sm:text-sm uppercase font-dm-mono">
          Live Auctions
        </h3>
        <h2 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-gray-900 uppercase font-mocha tracking-wide">
          Bid and Buy Exclusive
          <br />
          Items Using{" "}
          <span className="inline-block font-dm-sans italic">$</span>APE
        </h2>
        <p className="text-sm sm:text-base leading-relaxed max-w-xs sm:max-w-sm md:max-w-md font-dm-sans">
          Explore a curated marketplace of unique items available for auction.
          Join live streams to bid in real-time and secure exclusive
          collectibles, products, and services. Powered by $APE, our platform
          brings together a community of collectors and creators with exclusive
          access to unique items.
        </p>
      </div>

      {/* Image Section */}
      <div className="flex justify-center items-center w-full md:w-1/2">
        <img
          src={combinedPhoneImage}
          alt="Combined Phone"
          className="rounded-lg w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg object-cover"
        />
      </div>
    </section>
  );
};

export default AuctionInfoSection;
