import React from "react";
import HeroBGImg from "../images/hero-bg.png";
import logo from "../images/logo.png";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

const NavBar = () => {
  return (
    <div className="relative">
      {/* Background Image with Gradient Overlay */}
      <div
        className="w-full h-[60vh] md:h-[80vh] bg-cover bg-center"
        style={{ backgroundImage: `url(${HeroBGImg})` }}
      >
        {/* Gradient Overlay */}
        <div className="absolute inset-0 bg-gradient-to-r from-[#0937A7]/100 via-[#0937A7]/50 to-[#0937A7]/20"></div>
      </div>

      {/* Nav Section */}
      <nav className="absolute top-0 w-full flex items-center justify-between px-4 sm:px-6 md:px-12 lg:px-24 py-4 border-b border-gray-300 bg-transparent">
        {/* Logo */}
        <div className="flex-shrink-0">
          <img src={logo} alt="Logo" className="h-8 md:h-10" />
        </div>

        {/* Navigation Buttons */}
        <div className="flex space-x-3 sm:space-x-4">
          <button className="text-xs sm:text-sm md:text-base text-white hover:text-gray-200">
            Features
          </button>
          <button className="text-xs sm:text-sm md:text-base px-3 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition duration-150">
            FAQ
          </button>
          <button className="text-xs sm:text-sm md:text-base px-3 sm:px-4 py-2 bg-white text-black rounded-md hover:bg-gray-200 flex items-center space-x-2 transition duration-150">
            <span>Get Access</span>
            <ArrowRightIcon className="w-4 h-4 sm:w-5 sm:h-5 text-gray-800" />
          </button>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="absolute top-0 w-full h-full flex flex-col items-start justify-center px-4 sm:px-6 md:px-12 lg:px-24 space-y-4 sm:space-y-6">
        <h1 className="text-5xl sm:text-5xl md:text-6xl lg:text-7xl text-white max-w-lg font-mocha font-bold tracking-wide leading-tight">
          BUY YOUR FAVORITE
          <br />
          LUXURY PRODUCTS
          <br />
          WITH <span className="inline-block font-dm-sans italic">$</span>APE
        </h1>

        <div className="flex space-x-2 sm:space-x-4">
          <button className="px-4 sm:px-6 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition duration-150">
            Learn More
          </button>
          <button className="px-4 sm:px-6 py-2 bg-white text-black rounded-md hover:bg-gray-200 flex items-center space-x-2 transition duration-150">
            <span>Get Access</span>
            <ArrowRightIcon className="w-4 h-4 sm:w-5 sm:h-5 text-gray-800" />
          </button>
        </div>
      </section>
    </div>
  );
};

export default NavBar;
