import React from "react";
import background from "../images/marketpalce-bg.png"; // Replace with the correct path
import MarketplaceImg from "../images/marketplace-1.png"; // Replace with the correct path

const MarketplaceSection = () => {
  return (
    <section
      className="relative flex flex-col md:flex-row items-center w-full p-4 sm:p-8 bg-cover bg-center text-white space-y-6 md:space-y-0 md:space-x-8"
      style={{ backgroundImage: `url(${background})` }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Content Wrapper */}
      <div className="relative flex flex-col md:flex-row items-center w-full space-y-6 md:space-y-0 md:space-x-8">
        {/* Left Image Section */}
        <div className="md:w-1/2 flex justify-center">
          <img
            src={MarketplaceImg}
            alt="Combined"
            className="rounded-lg shadow-lg w-full md:w-[90%] lg:w-[100%] object-cover"
          />
        </div>

        {/* Right Text Section */}
        <div className="flex flex-col items-center text-center md:items-start md:text-left space-y-4 md:w-1/2">
          <h3 className="text-xs sm:text-sm font-dm-mono uppercase">Marketplace</h3>
          <h2 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl uppercase font-mocha font-bold tracking-wide">
            Discover and <br /> Purchase Exclusive <br /> Goods with{" "}
            <span className="inline-block font-dm-sans italic">$</span>APE
          </h2>
          <p className="text-sm sm:text-base leading-relaxed font-dm-sans max-w-xs sm:max-w-sm md:max-w-md">
            Browse a diverse ecosystem of brands and creators offering unique
            products and services. From collectibles to luxury items, find
            something that speaks to you—all powered by $APE. Explore what Made
            By Apes holders have crafted, or join the community to access
            exclusive marketplace opportunities.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MarketplaceSection;
