import React from "react";
import image7 from "../images/image7.png";
import featureImage1 from "../images/featureImage1.png";
import featureImage2 from "../images/feature2.png";
import { ArrowRightIcon } from "@heroicons/react/24/solid";

const FeaturesSection = () => {
  return (
    <section className="flex flex-col items-center w-full p-4 sm:p-8 space-y-8 mt-2">
      {/* Title Section - centered alignment on smaller screens */}
      <div className="w-full max-w-5xl text-center md:text-left">
        <h4 className="text-xs sm:text-sm font-dm-mono uppercase mb-4">Features</h4>
        <h2 className="text-5xl sm:text-5xl md:text-6xl lg:text-7xl font-bold leading-tight uppercase font-mocha tracking-wide">
          Unlock the Full <br /> Potential
        </h2>
      </div>

      {/* Feature Boxes - responsive grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 sm:gap-8 w-full max-w-4xl items-center">
        {/* First Row: Image Box on Left, Text Box on Right */}
        <div
          className="relative w-full h-64 sm:h-80 md:h-96 lg:h-[28rem] bg-cover rounded-3xl overflow-hidden"
          style={{ backgroundImage: `url(${image7})` }}
        >
          <img
            src={featureImage1}
            alt="Feature Icon"
            className="absolute w-full h-full z-10 object-cover"
          />
        </div>

        <div
          className="relative w-full h-64 sm:h-80 md:h-96 lg:h-[28rem] bg-cover rounded-3xl text-center text-white flex flex-col items-center justify-center overflow-hidden"
          style={{ backgroundImage: `url(${image7})` }}
        >
          <div className="relative z-10 flex flex-col items-center px-4">
            <h3 className="text-xl sm:text-2xl font-semibold font-dm-sans">
              Profile Creation with <br /> Selling Features
            </h3>
            <p className="mt-2 max-w-xs sm:max-w-sm text-sm sm:text-base text-center">
              Create a personalized profile that integrates selling capabilities. Display and manage items for sale directly from your profile, keeping your followers engaged.
            </p>
            <button className="mt-4 px-3 sm:px-4 py-2 bg-white text-gray-800 rounded-md hover:bg-gray-200 flex items-center space-x-2">
              <span>Get Access</span>
              <ArrowRightIcon className="w-4 h-4 sm:w-5 sm:h-5 text-gray-800" />
            </button>
          </div>
        </div>

        {/* Second Row: Text Box on Left, Image Box on Right */}
        <div
          className="relative w-full h-64 sm:h-80 md:h-96 lg:h-[28rem] bg-cover rounded-3xl text-center text-white flex flex-col items-center justify-center overflow-hidden"
          style={{ backgroundImage: `url(${image7})` }}
        >
          <div className="relative z-10 flex flex-col items-center px-4">
            <h3 className="text-xl sm:text-2xl font-semibold">
              Live Stream with Real-<br />
              Time Auctions
            </h3>
            <p className="mt-2 max-w-xs sm:max-w-sm text-sm sm:text-base text-center">
              Stream live and auction products in real time, allowing viewers to engage and place bids instantly for an interactive shopping experience.
            </p>
            <button className="mt-4 px-3 sm:px-4 py-2 bg-white text-gray-800 rounded-md hover:bg-gray-200 flex items-center space-x-2">
              <span>Get Access</span>
              <ArrowRightIcon className="w-4 h-4 sm:w-5 sm:h-5 text-gray-800" />
            </button>
          </div>
        </div>

        <div
          className="relative w-full h-64 sm:h-80 md:h-96 lg:h-[28rem] bg-cover rounded-3xl overflow-hidden"
          style={{ backgroundImage: `url(${image7})` }}
        >
          <img
            src={featureImage2}
            alt="Feature Icon"
            className="absolute w-full h-full z-10 object-cover"
          />
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
